/**
 * @define SocialShare
 */

.SocialShare {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 8.125rem;
  height: 3.125rem;
  margin: 3.125rem 1.875rem;
  padding: 0 0.625rem;
  border-radius: 25px;
  overflow: visible;
  color: white;
  font-weight: bold;
  font-size: 1.125rem;
  background-color: var(--colour-main);
  cursor: pointer;
  transition: width 0.3s ease-out;

  @media (--xlarge) {
    margin: 3.125rem auto;
  }

  &-icons {
    width: 0;
    height: 100%;
    opacity: 0;
    transition: width 0.3s ease-out;
  }

  &-icon {
    display: inline-block;
    margin-right: 0.625rem;

    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }

  &-link {
    height: 1.25rem;

    &:active,
    &:focus {
      outline: none;
    }
  }

  &.is-open {
    width: 15.625rem;
    color: transparent;
    transition: width 0.2s ease-out;
  }

  &.is-open > .SocialShare-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 12.5rem;
    padding-left: 1.25rem;
    border-left: 1px solid white;
    opacity: 1;
    transition: width 0.2s ease-out;
  }

  &-tooltip {
    position: relative;
    display: inline-block;
    height: 1.25rem;

    &-text {
      position: absolute;
      bottom: 150%;
      left: 50%;
      z-index: 5;
      width: 7.5rem;
      margin-left: -3.75rem;
      padding: 5px;
      border-radius: 3px;
      color: #fff;
      font-size: 0.875rem;
      text-align: center;
      background-color: var(--colour-brand);
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s;

      &::after {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-color: var(--colour-brand) transparent transparent transparent;
        border-style: solid;
        border-width: 5px;
        content: '';
      }
    }

    &:hover &-text {
      visibility: visible;
      opacity: 1;
    }
  }
}
