/**
 * @define MainNav
 */

.MainNav {
  position: relative;
  z-index: var(--zindex-mainnav);
  flex: 0 0 auto;
  overflow: visible;
  color: var(--colour-brand);
  background-color: transparent;
  transition: var(--transition-default) color,
    var(--transition-default) background-color;

  &-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    height: 3.125rem;
    margin: 0 !important;
    padding: 0 0.9375rem;
    border-bottom: 1px solid #eee;
    overflow: hidden;

    html.zd-theme-home & {
      border-bottom: 0;
    }

    @media (--small) {
      padding: 0 1.875rem;
    }

    @media (--mediumlarge) {
      height: 6.25rem;
      padding: 0 3.125rem;
      border-bottom: none;
    }
  }

  &-wrap {
    display: flex;
    justify-content: flex-end;
    max-width: 80%;
    height: 3.125rem;
    min-height: 3.125rem;
    overflow: hidden;
  }

  &-button {
    align-self: center;
    width: 6.625rem;
    height: 1.875rem;
    margin-right: 1.875rem;
    font-size: var(--font-size-tiny);

    @media (--mediumlarge) {
      width: 8.3125rem;
      height: 2.5rem;
      margin-right: 0;
      font-size: var(--font-size-small);
    }
  }

  &-list {
    display: none;
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (--mediumlarge) {
      display: flex;
      padding-left: 5.875rem;
    }
  }

  &-item {
    display: block;
    margin-top: 0 !important;
    font-size: var(--font-size-copy);
    font-family: var(--font-body);

    @media (--mediumlarge) {
      font-weight: bold;
      font-size: var(--font-size-small);
    }
  }

  &-link {
    display: block;
    padding: 0.9375rem var(--gutter-double) 0.9375rem 0;
    color: var(--colour-brand);
    text-decoration: none;
    vertical-align: center;
    transition: var(--transition-default) color;

    html.zd-theme-home & {
      color: white;
    }

    &.is-current,
    &:hover {
      color: color-mod(var(--colour-main) lightness(50%));

      html.zd-theme-home & {
        color: color-mod(var(--colour-main) lightness(70%));
      }
    }
  }

  &-hamburger {
    @media (--mediumlarge) {
      display: none;
    }
  }

  &-mobile {
    display: none;
    height: 0;
    opacity: 0;

    .MainNav-extended-list {
      margin-top: 20px;

      &-extra {
        padding-top: 40px;
      }
    }
  }

  /* ********************************   MOBILE NAV OPEN    ******************************* */

  &-mobile--open {
    position: fixed;
    top: 0;
    z-index: var(--zindex-sendback);
    display: block;
    width: 100%;
    height: auto;
    padding-top: 50px;
    overflow-y: scroll;
    font-weight: normal;
    font-size: 1.125rem;
    background-color: white;
    opacity: 1;
    transition: opacity 0.5s ease;

    .MainNav-mobileList {
      overscroll-behavior: contain;
      display: flex;
      flex-direction: column;
      height: 100vh;
      margin-top: 0;
      padding: 0;
      overflow: scroll;
      transition: margin-top 0.5s ease;
      -ms-overflow-style: none;

      &-main {
        padding-top: 23px;
        padding-bottom: 23px;
        background-color: white;
      }

      &-extra {
        flex-grow: 1;
        flex-shrink: 0;
        padding-top: 23px;
        padding-bottom: 63px;
        background-color: var(--colour-brand);
        transition: padding-top 0.5s ease;
        transition-delay: 0.1s;
      }

      &-item {
        display: flex;
        align-items: center;
        height: 4.375rem;
        margin: 0;
        padding-left: 1.875rem;
        color: var(--colour-brand);
        text-decoration: none;
        list-style: none;

        &--main {
          &:hover {
            color: var(--colour-brand);
            background-color: var(--colour-grey-lighter3);
          }
        }

        &--extra {
          color: var(--colour-white);

          &:hover {
            background-color: #33303c;
          }

          &:last-child {
            margin-bottom: 65px;
          }
        }
      }

      &-item.is-current,
      &-item:hover {
        color: color-mod(var(--colour-main) lightness(50%)) !important;
      }
    }
  }

  /* ********************************   STICKY    ******************************* */

  &--pinned {
    position: fixed;
    top: 0;
    background-color: var(--colour-brand);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transform: translateY(-200%) translateZ(0);
    transition: var(--transition-default) transform;

    @media (--mediumlarge) {
      .MainNav-contents {
        height: 4.0625rem;
        padding: 0 3.125rem;
      }
    }

    a {
      color: white;
      text-decoration: none;
    }

    a.is-current,
    a:hover {
      color: var(--colour-main);
    }

    .SubNav-link {
      color: var(--colour-brand);

      &:hover {
        color: var(--colour-brand);
      }
    }

    .SubNav-items {
      top: 60px;
    }

    .MainNav-contents {
      border-bottom: none;
    }

    .MainNav-hamburger-inner,
    .MainNav-hamburger-inner::before,
    .MainNav-hamburger-inner::after {
      background-color: white;
    }

    .MainNav-hamburger.is-active {
      .MainNav-hamburger-inner,
      .MainNav-hamburger-inner::before,
      .MainNav-hamburger-inner::after {
        background-color: white;
      }
    }

    .MainNav-mobile--open {
      display: none;
    }
  }

  &--opened {
    transform: translateY(0) translateZ(0);
  }

  &--forced {
    transition: none;
  }
}
