/**
 * @define HomeHero
 */

.HomeHero {
  display: flex;
  min-height: calc(100vh - 50px);

  @media (--mediumlarge) {
    min-height: calc(100vh - 100px);
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: white;

    @media (--mediumlarge) {
      justify-content: center;
    }
  }

  &-text {
    width: 100%;
    padding: 0 var(--content-padding-mobile);

    @media (--mediumlarge) {
      align-self: center;
      margin-bottom: 3.75rem;
    }

    @media (--xlarge) {
      padding: 0 var(--content-padding-desktop);
    }
  }

  &-title {
    margin-bottom: 1.875rem;
    color: white;
    font-weight: 700;
    font-size: 2.5rem;
    text-transform: capitalize;

    @media (--mediumlarge) {
      font-size: 3.75rem;
    }

    @media (--xlarge) {
      font-size: 5.625rem;
    }
  }

  &-copy {
    @media (--mediumlarge) {
      font-size: 1.875rem;
      line-height: 2.5rem;
    }
  }

  &-cta-wrap {
    display: none;

    @media (--mediumlarge) {
      display: block;
      margin: 3.125rem 0 0 0;
    }

    @media (--xlarge) {
      margin: 50px 0 0 0;
    }
  }

  &-controls {
    display: flex;
    flex-direction: column;
    margin-top: 3.125rem;

    @media (--mediumlarge) {
      position: absolute;
      right: 0;
      bottom: 0;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 768px;
      height: 130px;
      background-color: white;
      clip-path: polygon(30% 0, 100% 0, 100% 100%, 0% 100%);
    }
  }

  &-sections {
    display: flex;
    justify-content: space-between;
    order: 1;
    padding: 0 1rem 1rem 1rem;

    @media (--mediumlarge) {
      order: -1;
      width: 50%;
      padding: 0 1rem 0 0;
    }
  }

  &-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    min-width: 30% !important;
    height: 3.75rem;
    margin-top: 3.125rem;
    padding: 0;
    border: 0;
    border-radius: 3px;
    color: var(--colour-brand);
    font-weight: bold;
    font-size: 0.875rem;
    background-color: var(--colour-white);

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background-color: var(--colour-grey-lighter3);
      outline: none;
      transition: 0.25s background-color;
    }

    @media (--mediumlarge) {
      flex-direction: column;
      justify-content: center;
      height: 5rem;
      margin-top: 0;
      border-radius: 0;
      font-weight: 600;
      font-size: 1.125rem;

      &:hover,
      &:focus,
      &:active {
        background-color: var(--colour-white);
      }
    }
  }

  &-arrow {
    display: none;

    @media (--mediumlarge) {
      display: flex;
    }
  }

  &-trustpilot {
    @media (--mediumlarge) {
      width: 200px;
      margin-right: 1.875rem;
      padding-left: 1.875rem;
      border-left: 1px solid var(--colour-grey-lighter3);
    }
  }

  &-trustpilot-dark {
    display: none;

    @media (min-height: 500px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (--mediumlarge) {
      display: none;
    }
  }

  &-trustpilot-light {
    display: none;

    @media (--mediumlarge) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-picture {
    & .HomeHero-image,
    & .HomeHero-imageBg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: var(--zindex-sendback);
      min-width: 100%;
      height: 100vh;
      background-size: cover;
    }
  }
}
