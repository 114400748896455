/**
* @define LogoSlidingCarousel
*/

.LogoSlidingCarousel {
  &-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: var(--content-padding-large) var(--content-padding-mobile) 0
      var(--content-padding-mobile);
    background-color: #f8f8f8;

    @media (--mediumlarge) {
      padding: 9.375rem var(--content-padding-mobile) 0
        var(--content-padding-mobile);
    }
  }

  &-header {
    flex: 2 0 auto;
    width: 100%;

    @media (--xlarge) {
      max-width: 800px;
    }
  }

  &-title {
    margin-bottom: var(--content-padding-titles);
    font-weight: bold;
    font-size: var(--font-size-title-small);
    text-align: center;

    @media (--mediumlarge) {
      font-size: var(--font-size-title-medium);
    }
  }

  &-subtitle {
    padding-bottom: var(--content-padding-titles);
    font-size: var(--font-size-large);
    line-height: 1.875rem;
    text-align: center;

    @media (--mediumlarge) {
      padding-bottom: var(--content-padding-large);
      font-size: var(--font-size-large-desktop);
    }
  }

  &-slider {
    position: relative;
    width: 100%;
    height: 100px;
    margin: 0 auto;
    border-top: 1px solid #eee;
    overflow: hidden;
    background-color: #f8f8f8;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      z-index: 2;
      width: 20%;
      height: 100%;
      content: '';
    }

    &::before {
      left: 0;
      background: linear-gradient(
        to right,
        #f8f8f8 0%,
        rgba(238, 238, 238, 0) 100%
      );
    }

    &::after {
      right: 0;
      background: linear-gradient(
        to left,
        #f8f8f8 0%,
        rgba(238, 238, 238, 0) 100%
      );
    }
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    animation: move 30s linear infinite;

    &:hover {
      animation-play-state: paused;
    }

    @media (--mediumlarge) {
      animation: move 50s linear infinite;
    }
  }

  &-container .LogoSlidingCarousel-item {
    margin: 0;
    padding-right: 100px;
  }

  &-slider--narrow {
    .LogoSlidingCarousel-container {
      animation: move 10s linear infinite;

      @media (--mediumlarge) {
        min-width: 600px;
        animation: none;
      }
    }

    .LogoSlidingCarousel-item {
      padding-right: 150px;

      @media (--mediumlarge) {
        padding-right: 0;
      }
    }

    .LogoSlidingCarousel-extra {
      display: flex;
      align-items: center;
      height: 100%;

      @media (--mediumlarge) {
        display: none;
        visibility: hidden;
      }
    }
  }
}

@keyframes move {
  100% {
    transform: translateX(-50%);
  }
}
