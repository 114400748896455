/**
 * @define Hero
 */

.Hero {
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (--xlarge) {
      flex-wrap: wrap;
      justify-content: center;
      height: calc(100vh - 100px);
      min-height: 650px;
      overflow: hidden;

      @media (min-height: 800px) {
        position: relative;
      }
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: var(--content-padding-mobile);
    font-size: var(--font-size-hook-desktop);

    @media (--small) {
      padding: var(--content-padding-titles) var(--content-padding-mobile);
    }

    @media (--mediumlarge) {
      padding: var(--content-padding-large) var(--content-padding-mobile)
        var(--content-padding-titles);
    }

    @media (--xlarge) {
      align-self: flex-start;
      width: 26rem;
      margin: 0 0 0 var(--content-padding);
      padding: 0;
    }

    @media (--xxxlarge) {
      margin-left: var(--content-padding-large);
    }

    &-highlight {
      color: var(--colour-main);
    }
  }

  &-title {
    margin-bottom: var(--content-padding-mobile);
    font-weight: bold;
    font-size: var(--font-size-hook-desktop);
    font-family: var(--font-body);
    line-height: var(--font-size-hook-desktop);
    text-align: center;
    text-transform: capitalize;

    @media (--small) {
      margin-bottom: var(--content-padding-titles);
      font-size: 2.5rem;
      line-height: var(--font-size-hero);
    }

    @media (--mediumlarge) {
      font-size: var(--font-size-hero);
    }

    @media (--xlarge) {
      text-align: left;
    }
  }

  &-copy {
    color: var(--colour-brand);
    font-size: var(--font-size-hero-copy-small);
    line-height: var(--font-size-hook-desktop);
    text-align: center;

    @media (--small) {
      font-size: var(--font-size-large-desktop);
      line-height: 2.25rem;
    }

    @media (--xlarge) {
      max-width: 21.875rem;
      margin-bottom: var(--content-padding-titles);
      font-size: 1.375rem;
      text-align: left;
    }
  }

  &-image,
  &-image-container {
    width: 100%;
    height: 300px;
    min-height: 300px;
    background-position: 50% 50%;
    background-size: cover;

    @media (--small) {
      height: 500px;
      min-height: 500px;
    }

    @media (--xlarge) {
      order: 1;
      width: 75%;
      height: calc(100vh - 100px);
      background-position: 25% 50%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    }
  }

  &-picture {
    width: 100%;

    @media (--xlarge) {
      order: 1;
      height: 100%;
      min-height: 650px;
    }
  }

  &-cta-wrap {
    margin-top: var(--content-padding-mobile);
    margin-bottom: 1.3125rem;

    @media (--xlarge) {
      align-self: flex-start;
      margin: 0 0 0 var(--content-padding);
    }

    @media (--xxxlarge) {
      margin-left: var(--content-padding-large);
    }
  }

  &-trustpilot-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: var(--content-padding-small);
    padding-bottom: var(--content-padding-small);
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;

    @media (--xlarge) {
      display: none;
    }
  }

  &-trustpilot-desktop {
    display: none;

    @media (--xlarge) {
      display: flex;
      align-items: center;
      align-self: flex-start;
      width: 15.625rem;
      margin: 3.125rem 0 0 var(--content-padding);
      padding: 0;
      border: none;

      @media (min-height: 800px) {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    @media (--xxxlarge) {
      margin-left: var(--content-padding-large);
    }
  }

  /* a/b testing variant with logo carousel */

  &-logos {
    display: flex;
    flex-direction: column;

    & .Hero-content,
    .Hero-picture,
    .Hero-image {
      @media (--xlarge) {
        height: calc(100vh - 200px);
        min-height: 450px;
      }
    }

    &-logosContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-height: 800px) {
        position: relative;
      }

      @media (--xlarge) {
        flex-direction: row;
      }

      & .Hero-trustpilot {
        z-index: 3;
        width: 250px;
        margin: 0;
        padding: 1.875rem auto 3.125rem;
        border: 0;
        background-color: white;

        @media (--xlarge) {
          padding: 0 1.25rem 0 3.125rem;
        }

        @media (--xxxlarge) {
          width: 300px;
          padding: 0 1.25rem 0 var(--content-padding-large);
        }
      }

      & .LogoSlidingCarousel-slider {
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        background-color: white;

        &::before,
        &::after {
          background: none;
        }

        @media (--xlarge) {
          border: 0;
        }
      }

      & .LogoSlidingCarousel-item {
        filter: none;
      }
    }
  }
}
