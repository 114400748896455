/**
* @define FeatureImage
*/

.FeatureImage {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: var(--content-padding-large);

  &-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: var(--content-padding-large) var(--content-padding-mobile)
      var(--content-padding-titles);

    @media (--xlarge) {
      width: 50%;
      padding: 3.75rem;
    }

    @media (--xxlarge) {
      padding: 10.375rem;
    }
  }

  &-MediaContainer {
    position: relative;
    height: 100%;
  }

  &-StickyVideo {
    position: absolute;
    top: 50%;
    z-index: var(--zindex-stickyvideo__featureimage);
    width: 100%;
  }

  &-StickyVideo > &-StickyVideo-button {
    margin: 0 auto;
    font-weight: bold;
    background-color: rgba(35, 33, 41, 0.5);
  }

  &-title,
  &-copy {
    width: 100%;
  }

  &-title,
  &-copy {
    @media (--xlarge) {
      max-width: 25rem;
    }
  }

  &-title {
    margin-bottom: 3.125rem;
    font-size: var(--font-size-title-small);

    @media (--small) {
      font-size: var(--font-size-title-medium);
    }
  }

  &-copy {
    font-size: var(--font-size-copy);
    line-height: 1.875rem;
  }

  @media (--xlarge) {
    flex-direction: row;
    height: 100vh;
    min-height: 500px;
  }
}
