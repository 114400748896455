/**
* @define Arrow
*/

.Arrow {
  position: relative;
  display: flex;
  width: 100%;
  height: 2px;
  margin: 10px 0;
  border-radius: 3px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    margin-top: 1.25rem;
    border-radius: 3px;
    background: currentColor;
    content: '';
  }

  &-tip-upper,
  &-tip-lower {
    position: absolute;
    left: 100%;
    width: 15px;
    height: 2px;
    border-radius: 3px;
    transform-origin: left;

    &::after {
      display: block;
      width: 0;
      height: 2px;
      border-radius: 3px;
      background: currentColor;
      content: '';
    }
  }

  &-tip-upper {
    right: 0;
    transform: rotate(-145deg);
  }

  &-tip-lower {
    transform: rotate(145deg);
  }

  &-main {
    color: var(--colour-main);
    background-color: var(--colour-grey-lighter3);
  }

  &-white {
    color: var(--colour-white);
  }

  &-thicker {
    height: 3px;

    &::after {
      height: 3px;
    }

    & .Arrow-tip-upper,
    & .Arrow-tip-lower {
      height: 3px;

      &::after {
        height: 3px;
      }
    }
  }
}

.Arrow-activate-on-hover:hover .Arrow::after {
  animation: arrow var(--transition-time) forwards;
}

.Arrow-activate-on-hover:hover .Arrow-tip-upper::after {
  animation: arrow var(--transition-time) forwards;
  animation-delay: var(--transition-time);
}

.Arrow-activate-on-hover:hover .Arrow-tip-lower::after {
  animation: arrow var(--transition-time) forwards;
  animation-delay: var(--transition-time);
}

/* homeImageLinks animation variation - the arrow animation needs to wait for the image to animate in */

.Arrow-activate-on-hover:hover .HomeImageLinks-arrow::after {
  animation: arrow var(--transition-time) forwards;
  animation-delay: var(--transition-time);
}

.Arrow-activate-on-hover:hover .HomeImageLinks-arrow-tip-lower::after {
  animation: arrow var(--transition-time) forwards;
  animation-delay: calc(var(--transition-time) * 2);
}

.Arrow-activate-on-hover:hover .HomeImageLinks-arrow-tip-upper::after {
  animation: arrow var(--transition-time) forwards;
  animation-delay: calc(var(--transition-time) * 2);
}

@keyframes arrow {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
