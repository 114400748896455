/**
* @define ContactForm
*/

.ContactForm {
  &-contents {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: var(--gutter-xlarge);
    padding-bottom: var(--gutter-xlarge);

    @media (--large) {
      flex-wrap: none;
      padding-top: var(--gutter-xxlarge);
      padding-bottom: var(--gutter-xxlarge);
    }
  }

  &-title {
    flex: 1 0 100%;
    font-size: var(--font-size-hook);
    font-family: var(--font-body);

    @media (--large) {
      font-size: var(--font-size-hook-desktop);
    }
  }

  &-body {
    flex: 1 0 100%;
    margin-top: var(--gutter);
    margin-bottom: var(--gutter);
    line-height: 1.7em;

    @media (--large) {
      font-size: var(--font-size-large);
    }
  }

  &-column {
    flex: 0 0 100%;

    &:not(:first-child) {
      margin-top: var(--gutter-large);
    }

    @media (--large) {
      flex-basis: 50%;
      margin-top: 0 !important;
      padding-right: var(--gutter-xlarge);
    }
  }
}
