/**
 * @define StickyVideo
 */

.StickyVideo {
  &-format-icon.hidden {
    display: none;
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16.875rem;
    height: 4.625rem;
    margin: 6.25rem auto;
    color: var(--colour-white);
    background-color: var(--colour-main);

    &:hover {
      cursor: pointer;
    }

    &-copy {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 75%;
    }

    &-text {
      pointer-events: none;
    }
  }

  &-play-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4.625rem;
    height: 4.625rem;
    border-right: 1px solid white;
  }

  &-player {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: none;
    padding: 9.375rem 0;
    background: rgba(35, 33, 41, 0.7);

    @media (--mediumlarge) {
      padding: 6.25rem 12.5rem;
    }

    @media (--xlarge) {
      padding: 6.25rem 15.625rem;
    }

    @media (max-height: 420px) and (orientation: landscape) {
      padding: 0;
    }
  }

  &-player.active {
    display: flex;
    flex-direction: column;
  }

  &-player.StickyVideo-minimised {
    top: 65%;
    margin: auto;
    padding: 1px;
  }

  @media (min-width: 768px) {
    &-player.StickyVideo-minimised {
      left: 70%;
    }
  }

  &-player:not(.active) &-player-youtube {
    display: none;
  }

  &-player-youtube {
    width: 100%;
    height: 100%;
    border-bottom: 2rem solid #000;
  }

  &-player-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 2rem;
    margin: 0;
    background-color: #000;
  }

  &-player-menu .StickyVideo-button:not(:last-child) {
    margin-right: 5px;
  }

  &-menu-button {
    width: 1.875rem;
    margin-right: 1.25rem;
    color: var(--color-white);

    &:hover,
    &:focus,
    &:active {
      outline: none;
      cursor: pointer;
    }
  }
}
