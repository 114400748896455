/**
* @define Faqs
*/

.Faqs {
  &-contents {
    padding-top: var(--gutter-xlarge);
    padding-bottom: var(--gutter-xlarge);

    @media (--large) {
      padding-top: var(--gutter-xxlarge);
      padding-bottom: var(--gutter-xxlarge);
    }
  }

  &-title {
    font-size: var(--font-size-hook);
    font-family: var(--font-body);

    @media (--large) {
      flex-basis: 50%;
      padding-right: var(--gutter-xlarge);
      font-size: var(--font-size-hook-desktop);
    }
  }

  &-blurb {
    margin-top: var(--gutter);

    @media (--large) {
      margin-top: var(--gutter-large);
      font-size: var(--font-size-large);
    }
  }

  &-faqs {
    width: 100%;
    margin-top: var(--gutter);
    font-size: var(--font-size-copy);
    line-height: 1.875rem;

    @media (--large) {
      margin-top: var(--gutter-large);
      font-size: var(--font-size-large);
    }
  }

  &-question {
    margin: 0;
    font-weight: normal;
    font-size: var(--font-size-large);

    &:hover {
      color: var(--colour-main);
    }

    @media (--large) {
      font-size: 1.25rem;
    }
  }
}
