/**
* @define Mesh
*/

.Mesh {
  position: relative;
  z-index: 0;
  height: 300px;
  margin-bottom: var(--gutter-xlarge);
  background: #fff;
  background-image: radial-gradient(#d8d8d8 15%, transparent 0);
  background-size: 20px 20px;

  @media (--small) {
    height: 500px;
  }

  @media (--xlarge) {
    width: 50%;
    height: 100%;
    margin-bottom: 0;
  }

  &-image,
  &-image-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @media (--small) {
      height: 500px;
    }

    @media (--xlarge) {
      height: 100%;
      clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
    }
  }
}
