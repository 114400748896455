/**
* @define HookText
*/

.HookText {
  &--inverted {
    color: white !important;
    background-color: var(--colour-brand);
  }

  &-contents {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: var(--gutter-xlarge);
    padding-bottom: var(--gutter-xlarge);

    @media (--large) {
      flex-wrap: none;
      padding-top: var(--gutter-xxlarge);
      padding-bottom: var(--gutter-xxlarge);
    }
  }

  &-title {
    flex: 1 0 100%;
    font-weight: bold;
    font-size: var(--font-size-hook);
    font-family: var(--font-body);

    @media (--large) {
      flex-basis: 50%;
      padding-right: var(--gutter-xxxlarge);
      font-size: var(--font-size-hook-desktop);
    }
  }

  &-body {
    flex: 1 0 100%;
    margin-top: var(--gutter-large);
    padding-left: var(--gutter);
    line-height: 1.875em;

    :first-child {
      margin-top: 0;
    }

    @media (--large) {
      flex-basis: 50%;
      margin-top: 0;
      font-size: var(--font-size-large);
    }
  }

  &--two-column {
    .HookText-title {
      @media (--large) {
        flex-basis: 100%;
        padding-right: 0;
      }
    }

    .HookText-body {
      @media (--large) {
        margin-top: var(--gutter-large);
        padding-right: var(--gutter-large);
      }
    }
  }

  &--inverted {
    .HookText-body {
      color: white !important;
      font-size: var(--font-size-small);

      @media (--large) {
        font-size: var(--font-size-normal);
      }
    }
  }
}
