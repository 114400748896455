/**
* @define MarketingFooter
*/

.MarketingFooter {
  flex: 0 0 auto;
  height: auto;
  background-color: var(--colour-brand);
  clip-path: polygon(0 6%, 100% 0, 100% 100%, 0% 100%);

  @media (--mediumlarge) {
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
  }

  &-contents {
    display: flex;
    flex-direction: column;
    padding: var(--content-padding-large) var(--content-padding-mobile) 20px;
    color: white;

    @media (--mediumlarge) {
      padding: 12.5rem var(--content-padding-mobile) 5rem;
    }

    @media (--xlarge) {
      padding: 12.5rem var(--content-padding-desktop) 5rem;
    }

    @media (--xxlarge) {
      padding: var(--content-padding-larger-desktop)
        var(--content-padding-desktop) 5rem;
    }
  }

  &-links-main {
    width: 100%;

    @media (--xlarge) {
      display: flex;
      justify-content: space-between;
    }
  }

  &-social {
    display: block;
    margin-top: 1.25rem;
    margin-bottom: var(--gutter-xlarge);
    font-size: 1.5625rem;

    a {
      margin-right: var(--gutter-xlarge);
    }

    @media (--xlarge) {
      display: flex;
      justify-content: space-between;
      margin-top: 0;
    }
  }

  a {
    color: white !important;
    text-decoration: none;
    transition: var(--transition-default) color;

    &:hover {
      color: var(--colour-main) !important;
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-height: 250px;
    padding-left: 0;
    font-weight: bold;
    font-size: var(--font-size-small);
    font-family: var(--font-body);
    list-style-type: none;

    li {
      display: block;
      margin-top: 0 !important;
      margin-bottom: 1.875rem;
    }

    @media (--mediumlarge) {
      flex-direction: row;

      li:not(:last-child) {
        margin-right: 1.125rem;
      }
    }
  }

  &-links-extra {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (--mediumlarge) {
      flex-direction: row;
    }
  }

  &-links-more {
    height: auto;
    font-weight: normal;
    font-size: var(--font-size-tiny);

    @media (--mediumlarge) {
      li {
        margin-bottom: 0;
      }
    }
  }

  &-copyright {
    margin-right: 1.125rem;
    margin-bottom: 3.125rem;
    color: white;
    font-size: var(--font-size-small);
    font-size: var(--font-size-tiny);
    white-space: nowrap;

    @media (--mediumlarge) {
      margin-bottom: 0;
    }
  }

  &-smallprint {
    flex: 1 1 auto;
    margin-bottom: 3.125rem;
    color: #d7d7d7;
    font-size: 0.625rem;

    @media (--large) {
      margin-top: 0;
    }
  }
}
