/**
* @define KeyFeatures
*/

.KeyFeatures {
  &-contents {
    display: flex;
    flex-direction: column;
    padding: var(--content-padding-large) var(--content-padding-mobile);

    @media (--xlarge) {
      flex-direction: row;
      justify-content: space-between;
      min-width: 100%;
      padding: var(--content-padding-xlarge) var(--content-padding-desktop);
    }

    @media (--xxlarge) {
      padding: var(--content-padding-xlarge)
        var(--content-padding-larger-desktop);
    }
  }

  &-item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      padding-bottom: var(--content-padding-titles);
    }

    @media (--xlarge) {
      &:not(:first-child) {
        margin-top: var(--gutter-xlarge);
      }

      &:not(:last-child) {
        margin-right: 100px;
        padding-bottom: 0;
      }

      display: block;
      min-width: 18.75rem;
      max-width: 25rem;
      margin-top: 0 !important;
      margin-top: 0;
    }
  }

  &-item-title {
    position: relative;
    margin-bottom: var(--content-padding-titles);
    font-weight: bold;
    font-size: var(--font-size-copy);
    font-family: var(--font-body);

    @media (--small) {
      font-size: var(--font-size-large-desktop);
    }
  }

  &-item-copy {
    font-size: var(--font-size-copy);
    line-height: 1.875rem;
  }
}
