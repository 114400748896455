.BlogPostList {
  margin: var(--content-padding-large) 0;

  @media (--xlarge) {
    margin: var(--content-padding-xlarge) 0;
  }

  &-titleWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3.125rem;
    padding-right: var(--content-padding-mobile);
    padding-left: var(--content-padding-mobile);

    @media (--xlarge) {
      max-width: 800px;
      margin: 0 auto;
      text-align: center;
    }

    @media (--xxxlarge) {
      max-width: 900px;
      margin: 0 auto;
    }
  }

  &-title {
    margin-bottom: 3.125rem;
    font-weight: bold;
    font-size: var(--font-size-title-small);

    @media (--small) {
      font-size: var(--font-size-title-medium);
    }
  }

  &-subtitle {
    width: 100%;
    font-size: var(--font-size-copy);
    line-height: 1.875rem;

    @media (--xlarge) {
      font-size: var(--font-size-large-desktop);
      text-align: center;
    }
  }

  &-grid {
    padding: 0;

    @media (--xlarge) {
      margin: 6.25rem 3.75rem 0;
    }

    @media (--xxlarge) {
      margin-right: 10.375rem;
      margin-left: 10.375rem;
    }
  }

  &-item {
    width: 100%;
    height: 28.125rem;
    margin-top: 0 !important;
    margin-bottom: 6.25rem;

    @media (--small) {
      height: 30.625rem;
    }

    @media (--xlarge) {
      float: left;
      width: calc((100% - 12.5rem) / 3);
      height: 25rem;
      margin-bottom: 7.5rem;

      &:not(:last-child) {
        margin-right: 6.25rem;
      }
    }

    @media (--xxxlarge) {
      width: calc((100% - 15rem) / 3);
      height: 30.625rem;

      &:not(:last-child) {
        margin-right: 7.5rem;
      }
    }
  }

  &-row:last-child &-item {
    &:last-child {
      margin-bottom: 0;
    }

    @media (--xlarge) {
      margin-bottom: 0;
    }
  }
}
