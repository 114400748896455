.BlogPost {
  &-main {
    margin: var(--content-padding-large) 0;

    @media (--xlarge) {
      margin: var(--content-padding-xlarge) 0;
    }
  }

  &-container {
    padding: 0 var(--gutter-large);

    @media (--xlarge) {
      max-width: var(--content-width-large);
      margin: 0 auto;
      padding: 0;
    }

    @media (--xxlarge) {
      max-width: var(--content-width-xlarge);
    }
  }

  &-header {
    @media (--xlarge) {
      text-align: center;
    }
  }

  &-title {
    margin: 0 0 var(--content-padding-titles);
    font-weight: var(--font-weight-headings-new);
    font-size: 30px;

    @media (--xlarge) {
      font-size: 40px;
    }
  }

  &-meta {
    margin-bottom: var(--content-padding-titles);
    color: #9b9b9b;
    font-weight: bold;
    font-size: var(--font-size-small);
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  &-subheading {
    margin-bottom: 3.125rem;
    font-size: var(--font-size-copy);
    line-height: 1.667;

    @media (--small) {
      font-size: var(--font-size-large-desktop);
      line-height: 1.5;
    }

    @media (--xlarge) {
      margin-bottom: var(--content-padding-large-desktop);
      padding: 0 var(--content-padding-desktop);
    }
  }

  &-cover-image,
  &-cover-image-container {
    width: 100%;
    height: 17.5rem;
    margin-bottom: 3.125rem;
    background-position: top center;
    background-size: cover;

    @media (--small) {
      height: 18.75rem;
    }

    @media (--mediumlarge) {
      height: 31.25rem;
    }

    @media (--xlarge) {
      width: calc(100% - (2 * var(--content-padding-desktop)));
      max-height: 43.75rem;
      margin: 0 var(--content-padding-desktop)
        var(--content-padding-large-desktop);
    }

    @media (--xxlarge) {
      width: calc(100% - 20.75rem);
      margin-right: 10.375rem;
      margin-left: 10.375rem;
    }
  }

  &-content {
    font-size: var(--font-size-copy);
    line-height: 1.667;

    /* Children are rendered using a Contentful "Rich Text" helper library.
       The renderer uses plain elements without classes so the "!important!"
       rule is used to ensure the below styles are applied to the user
       generated content. */

    p:not(:last-child) {
      margin-bottom: 2.5rem !important;
    }
  }
}
