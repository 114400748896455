/**
* @define HomeImageLinks
*/

.HomeImageLinks {
  position: relative;
  margin: var(--section-margin-mobile) 0;

  /* mesh */

  &::before {
    position: absolute;
    top: 60%;
    left: 0;
    z-index: var(--zindex-sendback);
    width: 16.625rem;
    height: 8.75rem;
    background: transparent;
    background-image: radial-gradient(#d8d8d8 15%, transparent 0);
    background-size: 1.25rem 1.25rem;
    content: '';
  }

  @media (--xlarge) {
    margin: var(--section-margin-desktop) 0;
  }

  &-header {
    padding: 0 var(--content-padding-mobile);

    @media (--xlarge) {
      max-width: 50rem;
      margin: 0 auto;
      padding: 0 var(--content-padding-desktop);
    }

    @media (--xxxlarge) {
      max-width: 56.25rem;
    }
  }

  &-heading {
    margin-bottom: var(--heading-margin-mobile);
    font-weight: bold;
    font-size: var(--font-size-hook-desktop);

    @media (--mediumlarge) {
      font-size: 2.5rem;
    }

    @media (--xlarge) {
      text-align: center;
    }
  }

  &-subheading {
    margin-bottom: var(--heading-margin-mobile);
    font-size: var(--font-size-copy);
    line-height: 1.875rem;

    @media (--mediumlarge) {
      font-size: var(--font-size-large-desktop);
      line-height: 2.5rem;
    }

    @media (--xlarge) {
      max-width: 50rem;
      margin-bottom: 6.25rem;
      text-align: center;
    }
  }

  &-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 100%;
    height: 16.875rem;

    padding: 0 var(--content-padding-mobile);
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (--mediumlarge) {
      justify-content: center;
      height: 25.625rem;
    }

    @media (--xlarge) {
      padding: 0 var(--content-padding-desktop);
    }

    @media (--xxlarge) {
      height: 32.5rem;
      padding: 0 var(--content-padding-larger-desktop);
    }
  }

  &-link {
    position: relative;
    display: inline-block;
    min-width: 12.5rem;

    &:not(:last-child) {
      margin-right: 1.875rem;

      @media (--xlarge) {
        margin-right: 6.25rem;
      }
    }

    @media (--mediumlarge) {
      min-width: 18.125rem;
    }
  }

  &-image,
  &-imageBg {
    width: var(--image-mobile-width);
    height: var(--image-mobile-height);
    background-position: center;
    background-size: cover;
    transition: all var(--transition-default);

    @media (--mediumlarge) {
      width: var(--image-mediumlarge-width);
      height: var(--image-mediumlarge-height);
    }

    @media (--xxlarge) {
      width: var(--image-xxlarge-width);
      height: var(--image-xxlarge-height);
    }

    @media (--xxxlarge) {
      width: var(--image-xxxlarge-width);
      height: var(--image-xxxlarge-height);
    }
  }

  &-link:hover {
    .HomeImageLinks-linkText {
      top: calc(
        var(--image-mobile-height-hovered) - var(--linktext-height-hovered)
      );
      transition: all var(--transition-default);

      @media (--mediumlarge) {
        top: calc(
          var(--image-mediumlarge-height-hovered) -
            var(--linktext-height-hovered)
        );
      }

      @media (--xxlarge) {
        top: calc(
          var(--image-xxlarge-height-hovered) - var(--linktext-height-hovered)
        );
      }

      @media (--xxxlarge) {
        top: calc(
          var(--image-xxxlarge-height-hovered) - var(--linktext-height-hovered)
        );
      }
    }

    .HomeImageLinks-linkSubtitle {
      display: block;
    }

    .HomeImageLinks-image,
    .HomeImageLinks-imageBg {
      width: var(--image-mobile-width-hovered);
      height: var(--image-mobile-height-hovered);
      transition: all var(--transition-default);

      @media (--mediumlarge) {
        width: var(--image-mediumlarge-width-hovered);
        height: var(--image-mediumlarge-height-hovered);
      }

      @media (--xxlarge) {
        width: var(--image-xxlarge-width-hovered);
        height: var(--image-xxlarge-height-hovered);
      }

      @media (--xxxlarge) {
        width: var(--image-xxxlarge-width-hovered);
        height: var(--image-xxxlarge-height-hovered);
      }
    }
  }

  &-linkText {
    position: absolute;
    top: calc(var(--image-mobile-height) - var(--linktext-height));
    padding: 0 1.125rem;
    transition: all var(--transition-default);

    & {
      color: white;
      text-decoration: none;
    }

    @media (--mediumlarge) {
      top: calc(var(--image-mediumlarge-height) - var(--linktext-height));
    }

    @media (--xxlarge) {
      top: calc(var(--image-xxlarge-height) - var(--linktext-height));
    }

    @media (--xxxlarge) {
      top: calc(var(--image-xxxlarge-height) - var(--linktext-height));
    }
  }

  &-linkTitle {
    margin: 0;
    font-weight: bold;
    font-size: var(--font-size-copy);

    @media (--mediumlarge) {
      font-size: var(--font-size-large-desktop);
    }
  }

  &-linkSubtitle {
    display: none;
    margin: 0;
    font-size: var(--font-size-small);

    @media (--mediumlarge) {
      font-size: var(--font-size-copy);
    }
  }

  &-arrow {
    width: 65%;
  }
}
