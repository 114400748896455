.BlogPostCard {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  /* Drop shadow animation */

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0 68px 132px -72px rgba(0, 0, 0, 0.52);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    content: '';
  }

  &:hover::before {
    opacity: 1;
  }

  /* Background image */

  &-bg-container {
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &-bg,
  &-bg-image-container {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/default-blog-post-cover.png');
    background-position: center;
    background-size: cover;
    transition: transform 0.3s ease-in-out;
  }

  &:hover &-bg {
    transform: scale(1.2);
  }

  /* Text content */

  &-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    color: var(--colour-black);
  }

  /* always visible */

  &-details {
    margin-bottom: -0.5px; /* Fix small gap that sometimes appears */
    padding: 1rem 11% 1rem 1rem;
    background: var(--colour-white);
    clip-path: polygon(0 0, 88% 0, 100% 100%, 0 100%);
  }

  /* initially hidden */

  &-extra {
    overflow: hidden;
    background: var(--colour-white);
  }

  /* transition to default state */

  &-extra-content {
    margin-bottom: -50%;
    transform: translateY(10%);
    opacity: 0;
    transition: margin-bottom 0.3s ease-in-out, transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
  }

  /* transition to hovered state */

  &:hover &-extra-content {
    margin: 0;
    transform: translateY(0);
    opacity: 1;
    transition: margin-bottom 0.3s ease-in-out, transform 0.5s ease-in-out,
      opacity 1s ease-in-out;
  }

  &-category {
    margin-bottom: 0.3125rem;
    color: #9b9b9b;
    font-weight: 700;
    font-size: 0.625rem;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  &-title {
    font-weight: 700;
    font-size: 1.25rem;
  }

  &-subtitle {
    padding: 1rem;
    line-height: 1.5rem;
  }
}
