.BlogPostCarousel {
  position: relative;

  /* mesh */

  &::before {
    position: absolute;

    /* bottom = 1/2 image height + bottom margin */

    /* to position the mesh in the middle of the image */
    bottom: 200px;
    left: 0;
    z-index: 8;
    width: 15.625rem;
    height: 8.75rem;
    background: transparent;
    background-image: radial-gradient(#d8d8d8 15%, transparent 0);
    background-size: 1.25rem 1.25rem;
    content: '';

    @media (--xlarge) {
      bottom: 375px;
      width: 26.875rem;
      height: 15.3125rem;
    }

    @media (--xxxlarge) {
      bottom: 495px;
    }
  }

  &-grey {
    background-color: #f9f9f9;
  }

  &-heading {
    display: flex;
    flex-direction: column;
    padding: 0 var(--content-padding-mobile);

    @media (--xlarge) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0 var(--content-padding-desktop);

      .BlogPostCarousel-titleWrap {
        max-width: 43.75rem;
      }
    }

    @media (--xxlarge) {
      padding: 0 var(--content-padding-larger-desktop);
    }
  }

  &-titleWrap {
    margin-top: 6.25rem;
    margin-bottom: 3.125rem;

    @media (--xlarge) {
      margin-top: 15.625rem;
    }
  }

  &-title {
    margin-bottom: 3.125rem;
    font-size: var(--font-size-title-small);

    @media (--small) {
      font-size: var(--font-size-title-medium);
    }
  }

  &-subtitle {
    font-size: var(--font-size-copy);

    @media (--small) {
      font-size: var(--font-size-large-desktop);
    }
  }

  &-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 3.125rem;
    cursor: pointer;

    @media (--mediumlarge) {
      justify-content: flex-start;
    }

    @media (--xlarge) {
      max-height: 3.125rem;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 7.8125rem;
    height: 3.125rem;
    padding: 0.625rem;
    border: 1px solid #eee;
    border-radius: 2px;
    color: var(--colour-main);
    background-color: white;

    &:not(:last-child) {
      margin-right: 0.625rem;
    }

    &-left {
      justify-content: flex-start;
    }

    &-right {
      justify-content: flex-end;
    }

    &:hover {
      cursor: pointer;
    }

    &:active,
    &:focus {
      background-color: #f9f9f9;
      outline: none;
    }

    @media (--small) {
      width: 10.9375rem;
    }

    @media (--mediumlarge) {
      width: 7.8125rem;
    }
  }

  &-container {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 100%;

    /* height = image height + bottom margin */
    height: 380px;
    padding: 0 var(--content-padding-mobile);
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    @media (--xlarge) {
      height: 740px;
      padding: 0 var(--content-padding-desktop);
    }

    @media (--xxlarge) {
      padding: 0 var(--content-padding-larger-desktop);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-item {
    z-index: 9;
    display: inline-block;
    min-width: 12.5rem;
    height: 17.5625rem;

    /* the gap between the items should be less than side padding
    in order to indicate ends */

    &:not(:last-child) {
      margin-right: 1.25rem;
    }

    /* adjust blog post cards behaviour and sizes */

    .BlogPostCard-title {
      line-height: 1.375rem;

      @media (--xlarge) {
        line-height: 1.8125rem;
      }
    }

    .BlogPostCard-extra-content {
      margin-bottom: -150%;
      transform: translateY(10%);
    }

    .BlogPostCard {
      @media (max-width: 1200px) {
        &:hover .BlogPostCard-extra-content {
          margin-bottom: -150%;
          transform: translateY(10%);
        }
      }
    }

    @media (--xlarge) {
      min-width: 21.875rem;
      height: 30.625rem;

      &:not(:last-child) {
        margin-right: 3.75rem;
      }
    }
  }
}
