html.zd-marketing {
  body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--colour-brand);
    font-family: var(--font-body);
    text-decoration-skip-ink: auto;
    text-rendering: optimizeLegibility;
    -ms-overflow-style: none;

    /* stylelint-disable */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    /* stylelint-enable */
  }

  /* lists */

  h1:not([class]) {
    margin: 1.875rem 0;
    font-weight: bold;
    font-size: 1.875rem;
    text-align: center;

    @media (--small) {
      margin: 3.125rem 0;
      font-size: 2.5rem;
    }

    @media (--mediumlarge) {
      margin: 6.25rem 0 3.125rem 0;
      font-size: 3.125rem;
    }
  }

  h2:not([class]) {
    margin: 3.125rem 0;
    font-size: 1.875rem;

    @media (--mediumlarge) {
      font-size: 2.5rem;
      text-align: center;
    }
  }

  ul:not([class]) {
    list-style-type: disc;
  }

  li:not([class]) {
    &:not(:first-child) {
      margin-top: var(--gutter-small);

      @media (--medium) {
        margin-top: var(--gutter);
      }
    }
  }

  ol:not([class]) {
    list-style-type: decimal;
  }

  a:not([class]) {
    color: var(--colour-main);
    text-decoration: none;

    &:hover {
      color: var(--colour-accent);
    }
  }

  @media (--medium) {
    a[href^='tel:'] {
      color: var(--colour-brand);
      text-decoration: none;
      pointer-events: none;
    }
  }

  .pause-animation {
    animation-play-state: paused;
  }

  .noscroll {
    overflow: hidden;
  }

  .hidden {
    z-index: -1;
    width: 0;
    opacity: 0;
  }

  .image-object-fit {
    object-fit: cover;

    /* needed for object-fit polyfill to work in IE */

    font-family: 'object-fit: cover';
  }
}
