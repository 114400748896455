/** @define utilities */

/* Often we need a none without !important so jQuery animations will work */

.u-display-none {
  display: none;
}

.u-hidden {
  display: none !important;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-text-center {
  text-align: center;
}

/* font weight */

.u-bold {
  font-weight: 600;
}

.u-light {
  font-weight: 300;
}

.u-normal {
  font-weight: normal;
}

/* Margin top helpers */

.u-push-1 {
  margin-top: var(--gutter-small);
}

.u-push-2 {
  margin-top: var(--gutter);
}

.u-push-3 {
  margin-top: var(--gutter-large);
}

.u-push-4 {
  margin-top: var(--gutter-xlarge);
}

.u-push-large {
  margin-top: calc(var(--gutter-large) * 2);
}

.u-push-xlarge {
  margin-top: calc(var(--gutter-xlarge) * 2);
}

/* Margin top helpers tenant portal */

.u-tenant-portal-push-2 {
  margin-top: var(--gutter);

  @media (--mediumlarge) {
    margin-top: var(--gutter-double);
  }
}

/* sometimes we just need to remove the margin to reuse a component */

.u-m0 {
  margin: 0 !important;
}

/*
Margin top helpers in Raissa designs for the new tenant flow.
Note that these refer to the sizes in pixels for mobile, for instance,
m16 stands for **M**argin **16**px
*/

.u-m4 {
  margin-top: 0.25rem !important;
}

.u-m8 {
  margin-top: 0.5rem !important;

  @media (--mediumlarge) {
    margin-top: 1rem !important;
  }
}

.u-m12 {
  margin-top: 0.75rem !important;
}

.u-m16 {
  margin-top: 1rem !important;

  @media (--mediumlarge) {
    margin-top: 2rem !important;
  }
}

.u-m24 {
  margin-top: 1.5rem !important;

  @media (--mediumlarge) {
    margin-top: 2rem !important;
  }
}

/* Vertical alignment */

.u-valign-top {
  vertical-align: top;
}

.u-valign-middle {
  vertical-align: middle;
}

.u-valign-bottom {
  vertical-align: bottom;
}

/* element display type overrides */

.u-block {
  display: block;
}

.u-inline-block {
  display: inline-block;
}

.u-flex {
  display: flex;
}

.u-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.u-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.u-flex-col {
  display: flex;
  flex-direction: column;
}

.u-flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.u-inline-flex {
  display: inline-flex;
}

.u-flex-expand {
  flex: 2 0 auto;
}

/* float */

.u-margin-auto {
  margin: 0 auto;
}

.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

/* clearfix */

.u-clearfix::after {
  display: table;
  clear: both;
  content: '';
}

/* flexbox utils */

.u-flex-filler {
  flex: 2 1 auto;
}

/* force hardware rendering (GPU) */

.u-accel {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

/* text colours */

.u-col-brand {
  color: var(--colour-brand);
}

.u-col-primary {
  color: var(--colour-main);
}

.u-col-primary-forced {
  color: var(--colour-main);
}

.u-col-tenants {
  color: var(--colour-main);
}

.u-col-agents {
  color: var(--colour-main);
}

.u-col-main {
  color: var(--colour-main);
}

.u-col-zd-black {
  color: var(--colour-brand);
}

.u-col-error {
  color: var(--colour-error);
}

.u-col-error-orange {
  color: var(--colour-error-orange);
}

.u-col-white {
  color: var(--colour-white);
}

.u-col-grey-light {
  color: var(--colour-grey-copy-light);
}

.u-col-green {
  color: var(--colour-green);
}

/* tranition on all props */

.u-transition {
  transition: all var(--transition-default);
}
