/**
* @define InfoTable
*/

.InfoTable {
  &-contents {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: var(--gutter-xlarge);
    padding-bottom: var(--gutter-xlarge);

    @media (--large) {
      flex-wrap: none;
      padding-top: var(--gutter-xxlarge);
      padding-bottom: var(--gutter-xxlarge);
    }
  }

  &-title {
    flex: 1 0 100%;
    font-weight: bold;
    font-size: var(--font-size-hook);
    font-family: var(--font-body);

    @media (--large) {
      flex-basis: 50%;
      padding-right: var(--gutter-xxxlarge);
      font-size: var(--font-size-hook-desktop);
    }
  }

  &-body {
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: var(--gutter-large);

    @media (--large) {
      flex-basis: 50%;
      margin-top: 4px;
    }
  }

  &-row {
    align-self: stretch;

    &:not(:first-child) {
      margin-top: var(--gutter-large);
      padding-top: var(--gutter-large);
      border-top: 1px solid var(--colour-grey-lighter);
    }
  }

  &-row-title {
    font-weight: bold;
    font-family: var(--font-body);
  }

  &-row-body {
    display: flex;
    justify-content: space-between;
    margin-top: var(--gutter);

    @media (--large) {
      margin-top: var(--gutter-xlarge);
    }
  }

  &-row-copy {
    flex: 2 1 auto;
    margin-right: var(--gutter);

    :first-child {
      margin-top: 0;
    }
  }

  &-graphic {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    padding: var(--gutter) var(--gutter-smaller);
    border: 1px solid var(--colour-grey-lighter);
    border-top: 2px solid var(--colour-main);
    font-weight: bold;
    font-size: var(--font-size-large);
    font-family: var(--font-headings);
    text-align: center;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

    @media (--large) {
      width: 120px;
      padding: var(--gutter);
    }
  }
}
