/*
*@define SubNav
*/

.SubNav {
  &-items {
    position: absolute;
    top: 80px;
    z-index: var(--zindex-subnav);
    display: none;
    width: 21.875rem;
    height: calc(4 x var(--subnav-item-height));
    border-radius: 3px;
    background: var(--colour-grey-lighter4);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

    &.is-open {
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
  }

  &-item {
    height: var(--subnav-item-height);
    border-radius: 3px;
    transition: var(--transition-default);

    &:not(:last-child) {
      border-bottom: 1px solid var(--colour-grey-lighter);
    }

    &:hover {
      background-color: var(--colour-grey-lighter);
      cursor: pointer;
      transition: var(--transition-default);
    }

    &::before {
      position: absolute;
      top: -16px;
      left: 12px;
      display: block;
      width: 0;
      height: 0;
      border-right: 16px solid transparent;
      border-bottom: 16px solid var(--colour-grey-lighter4);
      border-left: 16px solid transparent;
      content: '';
    }
  }

  &-link {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0.5rem 1.25rem;
    color: var(--colour-brand);
    text-decoration: none;
    cursor: pointer;
  }

  &-icon {
    margin-right: var(--gutter);
  }

  &-title {
    font-weight: bold;
    font-size: var(--font-size-small);
  }

  &-subtitle {
    font-size: var(--font-size-small);
  }
}
